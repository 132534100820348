<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="recruitment" style="width:100vw">
  <div id="a12ad156">
    企业招聘
  </div>
  <el-button id="dcc8052d" @click="clickdcc8052d()">
    +添加职位
  </el-button>
  <el-table id="f50f5f9e" :data="tbd_f50f5f9e" stripe="" border="">
    <el-table-column prop="d79a630f" width="100px" label="序号"  align="center">
    </el-table-column>
    <el-table-column prop="ad527afa" label="岗位"  align="center">
    </el-table-column>
    <el-table-column prop="adcff704" label="发布日期"  align="center">
    </el-table-column>
    <el-table-column prop="fe86478a" width="100px"  align="center" label="状态">
    </el-table-column>
    <el-table-column prop="a928abf8" label="置顶" width="100px" align="center">
              <template slot-scope="scope">
            <el-switch
              v-model="scope.row.a928abf8"
              active-color="#13ce66"
              inactive-color="#999"
              @change="positionTopclick(scope.row)">
            </el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="a0a53dfa" label="操作"  width="300px" align="center">
          <template slot-scope="scope">
            <el-button
              @click="positionStopClick(scope.row)"
              type="text"
              size="small"
              >{{ scope.row.unstatus }}</el-button>
            <el-button
              @click="positionDeleteClick(scope.row)"
              type="text"
              size="small"
              >删除</el-button>
            <el-button
              @click="positionUpdateClick(scope)"
              type="text"
              size="small"
              >编辑</el-button>
          </template>
    </el-table-column>
  </el-table>
  <el-pagination id="a3c3377f">
  </el-pagination>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
DALINEWEB_ARTICLECENTER_V0X0X1_getPosition,
DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition,
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    tbd_f50f5f9e:[]
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
    this.getPositondata()
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clickdcc8052d(){
      this.$router.push('Newrecruitment')
    },
    //在此注释下方添加自定义函数或事件
    async positionDeleteClick(data){
        let dicinput = {
          PositionID:data.dal_id,
          status : 1
        }
        const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition(dicinput)
        console.log(res.retContent);
        if (res.content.resCode===0) {
          this.$message("删除成功")
          this.getPositondata()
        }
    },
    async positionTopclick(data){
        let dicinput = {PositionID:data.dal_id}
      if (data.a928abf8 === true) {
        dicinput.top = 1;
      } else {
        dicinput.top = 0;
      }
        const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition(dicinput)
        console.log(res.retContent);
        if (res.content.resCode===0) {
          this.$message("操作成功")
          this.getPositondata()
        }
    },
    async positionStopClick(data){
      let dicinput = {PositionID:data.dal_id,}
      console.log(data.unstatus);
      if (data.unstatus === "使用") {
        dicinput.status = 0;
      } else {
        dicinput.status = 2;
      }
        const res = await DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition(dicinput)
        console.log(res.retContent);
        if (res.content.resCode===0) {
          this.$message("操作成功")
          this.getPositondata()
        }
    },
  async getPositondata(){
      let dicinput = {
      }
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getPosition(dicinput)
      const positionlist =res.content.resContent
      console.log(positionlist);
      this.tbd_f50f5f9e = []
      positionlist.forEach(element => {
        element.d79a630f = element.dal_id
        element.ad527afa = element.PositionName
        element.adcff704 = element.dal_insertTime
        element.fe86478a = element.status === 0 ? "使用" : "停用";
        element.unstatus = element.status === 0 ? "停用" : "使用";
        element.a928abf8 = element.top === 1? true :false
        this.tbd_f50f5f9e.push(element)
      });
    },
    async positionUpdateClick(data){
      this.$router.push({
        path: 'Newrecruitment',
        query: {dal_id:data.row.dal_id},
      })
    }
  }

}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a12ad156{
  padding-top:100px;
}
#a12ad156{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#dcc8052d{
  width:120px;
  margin-top:30px;
  margin-left:1365px;
}
#dcc8052d{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#dcc8052d{
  color:#fff;
  font-size:13px;
  font-family:PingFang SC;
  border:transparent;
}
#f50f5f9e{
  width:1322px;
  margin-top:15px;
  margin-left:163px;
}
#f50f5f9e{
  display:inline-block;
  vertical-align: middle;
}
#a3c3377f{
  width:302px;
  margin-top:60px;
  padding-left:674px;
  padding-bottom:219px;
}
/*在此注释下方添加自定义样式*/
</style>
